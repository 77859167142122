import React from 'react';
import RestaurantHome from './Vrestauranthome'
import QuickSearch from './QuickSearch'



export default function Home() {

  return <div><React.Fragment><RestaurantHome/> <QuickSearch/> </React.Fragment></div>;
}
